import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_3 = { for: "name" }
const _hoisted_4 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = { for: "category" }
const _hoisted_7 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_8 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_9 = { for: "mealType" }
const _hoisted_10 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_11 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_12 = { for: "units" }
const _hoisted_13 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_14 = {
  key: 0,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_15 = { for: "unitWeight" }
const _hoisted_16 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_17 = {
  key: 1,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_18 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_19 = { for: "foodIntolerances" }
const _hoisted_20 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_21 = { for: "foodExclusions" }
const _hoisted_22 = { class: "section-header" }
const _hoisted_23 = { class: "p-grid" }
const _hoisted_24 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_25 = { for: "calories" }
const _hoisted_26 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_27 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_28 = { for: "protein" }
const _hoisted_29 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_30 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_31 = { for: "fat" }
const _hoisted_32 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_33 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_34 = { for: "carbohydrates" }
const _hoisted_35 = {
  key: 0,
  class: "p-invalid"
}

import inputHelper from '@/helpers/Input.helper';
import {
  createFoodForm,
  Food,
  FoodCategory,
  FoodDto,
  FoodUnit,
  isFormCreateValid,
  MealType,
} from '@/models/NutritionalPlan';
import { Intolerances } from '@/models/PatientInfo';
import router from '@/router';

import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, PropType, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodCreateModifyModal',
  props: {
  modify: {
    type: Boolean,
    default: () => {
      return false;
    },
  },
  foodList: {
    type: Array as PropType<Food[]>,
  },
},
  emits: ['food-updated'],
  setup(__props, { expose: __expose, emit: __emit }) {

const emits = __emit;

const props = __props;

const { t } = useI18n();
const toast = useToast();
const showDialog = ref(false);
const foodDto: Ref<FoodDto> = ref({} as FoodDto);
const validatedForm = computed(() => createFoodForm(foodDto.value));
const valid = computed(() => isFormCreateValid(validatedForm.value));
const waitSubmit = ref(false);
const showValidation = ref(false);

const categoryOptions = computed(() => {
  return Object.values(FoodCategory)
    .map((category) => ({
      name: t(`food.categories.${category}`),
      value: category,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

const mealTypeOptions = computed(() => {
  return Object.values(MealType)
    .map((type) => ({
      name: t(`food.mealTypes.${type}`),
      value: type,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

const foodUnitOptions = computed(() => {
  return Object.values(FoodUnit)
    .map((foodUnit) => ({
      name: t(`food.unit.${foodUnit}`),
      value: foodUnit,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

const foodIntolerancesOptions = computed(() => {
  return Object.values(Intolerances)
    .map((foodIntolerance) => ({
      name: t(`food.foodIntolerance.${foodIntolerance}`),
      value: foodIntolerance,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const openDialog = (defaultFood: Food) => {
  if (!defaultFood) {
    return;
  }
  showValidation.value = false;
  foodDto.value = inputHelper.createCopy<FoodDto>(new FoodDto(defaultFood));
  showDialog.value = true;
};

const onSubmit = async () => {
  showValidation.value = true;
  if (!valid.value) {
    return;
  }
  if (props.modify) {
    modifyFood(foodDto.value);
  } else {
    createFood(foodDto.value);
  }
};

onMounted(async () => {});

async function createFood(food: FoodDto) {
  waitSubmit.value = true;
  const result = await nutritionalPlanService.createFood(food);
  waitSubmit.value = false;
  if (result instanceof ServiceError) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorCreateFood')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } else {
    router.push(`/foods/${result.id}`);
    showDialog.value = false;
  }
}

async function modifyFood(modifiedFood: FoodDto) {
  console.log(modifiedFood);
  waitSubmit.value = true;
  const result = await nutritionalPlanService.updateFood(modifiedFood.id, modifiedFood);
  waitSubmit.value = false;
  if (result instanceof ServiceError) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorEditFood')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } else {
    toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditFood')}`, life: 3000 });
    emits('food-updated');
    showDialog.value = false;
  }
}

__expose({
  openDialog,
});

return (_ctx: any,_cache: any) => {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast),
    _createVNode(_component_Dialog, {
      visible: showDialog.value,
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((showDialog).value = $event)),
      header: __props.modify ? _ctx.$t('food.edit') : _ctx.$t('food.new'),
      modal: true,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.cancel'),
          class: "p-button-secondary p-button-medium",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (showDialog.value = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: __props.modify ? _ctx.$t('common.save') : _ctx.$t('common.add'),
          class: "p-button p-button-medium",
          loading: waitSubmit.value,
          onClick: onSubmit
        }, null, 8, ["label", "loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("form", null, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('food.name')) + "*", 1),
              _createVNode(_component_InputText, {
                id: "name",
                modelValue: foodDto.value.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((foodDto.value.name) = $event)),
                modelModifiers: { trim: true },
                required: "true",
                class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.name.valid })
              }, null, 8, ["modelValue", "class"]),
              (showValidation.value && !validatedForm.value.name.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t(`${validatedForm.value.name.message}`)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('food.category')) + "*", 1),
              _createVNode(_component_Dropdown, {
                id: "category",
                modelValue: foodDto.value.category,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((foodDto.value.category) = $event)),
                options: categoryOptions.value,
                "option-label": "name",
                "option-value": "value",
                class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.category.valid })
              }, null, 8, ["modelValue", "options", "class"]),
              (showValidation.value && !validatedForm.value.category.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t(`${validatedForm.value.category.message}`)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('food.mealType')) + "*", 1),
              _createVNode(_component_MultiSelect, {
                id: "mealType",
                modelValue: foodDto.value.mealTypes,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((foodDto.value.mealTypes) = $event)),
                options: mealTypeOptions.value,
                "option-label": "name",
                "option-value": "value",
                class: _normalizeClass(['multiselect-custom', { 'p-invalid': showValidation.value && !validatedForm.value.mealTypes.valid }])
              }, null, 8, ["modelValue", "options", "class"]),
              (showValidation.value && !validatedForm.value.mealTypes.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t(`${validatedForm.value.mealTypes.message}`)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('food.units')) + "*", 1),
              _createVNode(_component_Dropdown, {
                id: "units",
                modelValue: foodDto.value.units,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((foodDto.value.units) = $event)),
                options: foodUnitOptions.value,
                "option-label": "name",
                "option-value": "value",
                class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.units.valid })
              }, null, 8, ["modelValue", "options", "class"]),
              (showValidation.value && !validatedForm.value.units.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.$t(`${validatedForm.value.units.message}`)), 1))
                : _createCommentVNode("", true)
            ]),
            (foodDto.value.units === _unref(FoodUnit).u)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('food.unitWeight')) + "*", 1),
                  _createVNode(_component_InputNumber, {
                    id: "unitWeight",
                    modelValue: foodDto.value.unitWeight,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((foodDto.value.unitWeight) = $event)),
                    class: _normalizeClass({
              'p-invalid': showValidation.value && !validatedForm.value.unitWeight.valid,
            })
                  }, null, 8, ["modelValue", "class"]),
                  (showValidation.value && !validatedForm.value.unitWeight.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.$t(`${validatedForm.value.unitWeight.message}`)), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (foodDto.value.units === _unref(FoodUnit).u)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('food.foodIntolerances')) + "*", 1),
              _createVNode(_component_MultiSelect, {
                id: "foodIntolerances",
                modelValue: foodDto.value.foodIntolerances,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((foodDto.value.foodIntolerances) = $event)),
                options: foodIntolerancesOptions.value,
                "option-label": "name",
                "option-value": "value"
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('food.foodExclusions')) + "*", 1),
              _createVNode(_component_MultiSelect, {
                id: "foodExclusions",
                modelValue: foodDto.value.foodExclusions,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((foodDto.value.foodExclusions) = $event)),
                options: foodIntolerancesOptions.value,
                "option-label": "name",
                "option-value": "value"
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('food.values-100g')), 1),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('food.calories')) + "*", 1),
              _createVNode(_component_InputNumber, {
                id: "calories",
                modelValue: foodDto.value.calories,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((foodDto.value.calories) = $event)),
                required: "true",
                class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.calories.valid })
              }, null, 8, ["modelValue", "class"]),
              (showValidation.value && !validatedForm.value.calories.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_26, _toDisplayString(_ctx.$t(`${validatedForm.value.calories.message}`)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('food.protein')) + "*", 1),
              _createVNode(_component_InputNumber, {
                id: "protein",
                modelValue: foodDto.value.protein,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((foodDto.value.protein) = $event)),
                required: "true",
                class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.protein.valid })
              }, null, 8, ["modelValue", "class"]),
              (showValidation.value && !validatedForm.value.protein.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_29, _toDisplayString(_ctx.$t(`${validatedForm.value.protein.message}`)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('food.fat')) + "*", 1),
              _createVNode(_component_InputNumber, {
                id: "fat",
                modelValue: foodDto.value.fat,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((foodDto.value.fat) = $event)),
                required: "true",
                class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.fat.valid })
              }, null, 8, ["modelValue", "class"]),
              (showValidation.value && !validatedForm.value.fat.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_32, _toDisplayString(_ctx.$t(`${validatedForm.value.fat.message}`)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t('food.carbohydrates')) + "*", 1),
              _createVNode(_component_InputNumber, {
                id: "carbohydrates",
                modelValue: foodDto.value.carbohydrates,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((foodDto.value.carbohydrates) = $event)),
                required: "true",
                class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.carbohydrates.valid })
              }, null, 8, ["modelValue", "class"]),
              (showValidation.value && !validatedForm.value.carbohydrates.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_35, _toDisplayString(_ctx.$t(`${validatedForm.value.carbohydrates.message}`)), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})