import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }

import FoodCreateModifyModal from '@/components/food/FoodCreateModifyModal.vue';
import FoodTable from '@/components/food/FoodTable.vue';
import AppHeading from '@/components/HeadingComponent.vue';
import { Food } from '@/models/NutritionalPlan';
import router from '@/router';

import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { onMounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodsPage',
  setup(__props) {

const { t } = useI18n();
const toast = useToast();

const route = useRoute();

const foods: Ref<Food[] | undefined> = ref();
const modalFood = ref({} as Food);
const modalFoodId = ref('');
const isEditModal = ref(false);
const foodModalRef = ref();

const foodToDelete = ref<Food>();
const showDeleteDialog = ref(false);
const loadingDelete = ref(false);

const loadFoods = async () => {
  const result = await nutritionalPlanService.findAllFoods();
  if (!(result instanceof ServiceError)) {
    foods.value = result;
  }
};
onMounted(async () => {
  loadFoods();
});

const onAddFood = () => {
  isEditModal.value = false;
  const newFood = {} as Food;
  foodModalRef.value?.openDialog(newFood);
};

const onSeeFood = (food: Food) => {
  router.push(`/foods/${food.id}`);
};

const onEditFood = async (food: Food) => {
  isEditModal.value = true;
  foodModalRef.value?.openDialog(food);
};

const onDeactivatedChange = async (foodDto: Food) => {
  console.log(foodDto);

  loadingDelete.value = true;
  const result = await nutritionalPlanService.updateDeactivated(foodDto.id, foodDto.deactivated);

  if (result instanceof ServiceError) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorDeleteFood')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } else {
    toast.add({ severity: 'success', summary: `${t('messages.notifications.successUpdateFood')}`, life: 3000 });
  }

  loadingDelete.value = false;
};

const onDeleteClick = async (food: Food) => {
  foodToDelete.value = food;

  showDeleteDialog.value = true;
};

const onDelete = async () => {
  if (foodToDelete.value) {
    loadingDelete.value = true;
    const result = await nutritionalPlanService.deleteFood(foodToDelete.value.id);

    if (result instanceof ServiceError) {
      toast.add({
        severity: 'error',
        summary: `${t('messages.notifications.errorCreateFood')} ${t('messages.notifications.tryLater')}`,
        life: 3000,
      });
    } else {
      await loadFoods();
      foodToDelete.value = undefined;
      showDeleteDialog.value = false;
    }

    loadingDelete.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast),
    _createVNode(AppHeading, {
      title: _ctx.$t(_unref(route).meta.breadcrumb ? _unref(route).meta.breadcrumb[0].label : '')
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-purple p-button-icon",
          onClick: onAddFood
        }, {
          default: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('food.new')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(FoodTable, {
        foods: foods.value,
        onSeeFoodClick: onSeeFood,
        onEditFoodClick: onEditFood,
        onDeleteFoodClick: onDeleteClick,
        onDeactivateFoodClick: onDeactivatedChange
      }, null, 8, ["foods"])
    ]),
    _createVNode(FoodCreateModifyModal, {
      ref_key: "foodModalRef",
      ref: foodModalRef,
      food: modalFood.value,
      foodId: modalFoodId.value,
      modify: isEditModal.value,
      foodList: foods.value,
      onFoodUpdated: loadFoods
    }, null, 8, ["food", "foodId", "modify", "foodList"]),
    _createVNode(_component_Dialog, {
      visible: showDeleteDialog.value,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((showDeleteDialog).value = $event)),
      header: _ctx.$t('patient.deletePatient'),
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.cancel'),
          class: "p-button p-component p-button-secondary p-button-medium",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showDeleteDialog.value = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('common.delete'),
          class: "p-button p-component p-button-secondary p-button-medium delete-patient-confirm-button",
          loading: loadingDelete.value,
          onClick: onDelete
        }, null, 8, ["label", "loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('patient.sureToDelete', { name: foodToDelete.value?.name })), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('patient.deleteInfoMessage')) + ". " + _toDisplayString(_ctx.$t('messages.actionNotUndone')), 1)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})